/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/jquery.mmenu@7.3.3/dist/jquery.mmenu.min.js
 * - /gh/chennien/hearty.me@64b64c974c0f90b87f75b5ecf1e6bd0a7e00008f/public/alertify.custom.min.js
 * - /npm/firebase@8.10.1/firebase-app.js
 * - /npm/firebase@8.10.1/firebase-messaging.js
 * - /gh/chennien/alt.hearty.me@40914/public/common.head.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
